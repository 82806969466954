import Constant from "@/staticData/constant";
import { GET_JOBS } from "../GraphQL/Queries/Jobs.query";
import { jobResources } from "./SpecialSectionsLogic/SpecialSection.model";

export const specialComponentsData = {
  "section.assessment": {
    apiUrl: "/pages/assessments",
    apiPayload: {},
  },
  "section.statistics": {
    apiUrl: "/pages/statistics-data",
    apiPayload: {},
  },
  "section.latest-jobs": {
    apiUrl: "/pages/jobs?skip=0&limit=4",
    apiPayload: {},
  },
  "section.jobs": {
    query: GET_JOBS,
    queryPayload: {
      filterObject: {
        skip: 0,
        limit: Constant.PAGE_LIMIT,
      },
    },
    tableName: "generic-card-lists",
    tablePayload: {
      filter: "filters[type][$eq]=Assessment",
      query:
        "populate[image][fields][0]=url&populate[skills][fields][0]=display_name&populate[skills][populate][skill_icon][fields][0]=url&populate[level][populate]=*&populate[pills][populate]=*",
    },
    apiUrl: "/pages/assessments",
    apiPayload: {},
    staticData: {
      jobResources,
    },
  },
  "section.pc-auto": {
    sanitizeListData: true,
  },
  "section.courses-listing": {
    tableName: "generic-card-lists",
    // tablePayload: {
    //   filter: "filters[active][$eq]=true&pagination[limit]=100",
    //   query:
    //     "populate[image][fields][0]=url&populate[skills][fields][0]=display_name&populate[level][populate]=*",
    // },
    apiUrl: "/pages/assessments",
    apiPayload: {},
  },
};
