export const jobResources = {
  technical: [
    {
      link: "https://www.linkedin.com/in/abhisheketh/",
      title: "Follow Abhishek Smith for game programming content",
      mobileTitle: "Follow Abhishek - tech content",
      id: "abhishek-smith-linkedin",
      tags: ["Linkedin"],
    },
  ],

  nonTechnical: [
    {
      link: "https://www.linkedin.com/in/mayankgrover/",
      title: "Follow Mayank Grover for game development resources",
      mobileTitle: "Follow Mayank - career advice",
      id: "mayank-grover-linkedin",
      tags: ["Linkedin"],
    },
  ],
  skillCourseResources: {
    "c#": {
      link: "/course/c-beginner",
      title: "Free | C# | Midnight Pizza Fight",
      id: "course-midnight-pizza-fight",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    cpp: {
      link: "/course/beginner-cpp",
      title: "Free | C++ | Lethos",
      id: "course-beginner-cpp",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    sfml: {
      link: "/course/pong-sfml",
      title: "Free | C++ | Pong",
      id: "course-sfml",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
  },
};
