import axios from "axios";

class StrapiService {
  constructor() {
    this.token = process.env.STRAPI_TOKEN;
    this.client = axios.create({
      baseURL: process.env.STRAPI_BASE_URL,
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  #removeAttributeKey(obj) {
    const removeNestedKeys = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => this.#removeAttributeKey(item));
      } else if (obj !== null && typeof obj === "object") {
        const newObj = {};
        for (const key in obj) {
          if (key === "data") {
            const nestedObj = this.#removeAttributeKey(obj[key]);
            if (Array.isArray(nestedObj)) {
              newObj[key] = nestedObj;
            } else if (
              typeof nestedObj === "object" &&
              !Array.isArray(nestedObj)
            ) {
              Object.assign(newObj, nestedObj);
            }
          } else if (key === "attributes") {
            const nestedObj = this.#removeAttributeKey(obj[key]);
            if (typeof nestedObj === "object" && !Array.isArray(nestedObj)) {
              Object.assign(newObj, nestedObj);
            } else {
              newObj[key] = nestedObj;
            }
          } else if (
            key.includes("listData") &&
            typeof obj[key] === "object" &&
            obj[key].data
          ) {
            newObj[key] = this.#removeAttributeKey(obj[key].data);
          } else {
            newObj[key] = this.#removeAttributeKey(obj[key]);
          }
        }
        return newObj;
      }
      return obj;
    };

    return removeNestedKeys(obj);
  }
  async get(apiEndpoint, filter = "", query = "", getAllEntries = false) {
    try {
      const response = await this.client.get(
        `${apiEndpoint}?${filter}&${query}`
      );
      return this.#removeAttributeKey(
        getAllEntries ? response?.data?.data : response?.data?.data[0]
      );
    } catch (error) {
      console.error(
        "[Strapi Service] Error fetching data:",
        error.message,
        apiEndpoint
      );
      throw error;
    }
  }

  async getSingleDataType(dataType = "") {
    try {
      const response = await this.client.get(dataType);
      return this.#removeAttributeKey(response.data);
    } catch (error) {
      console.error("Error fetching single data type:", error);
      throw error;
    }
  }
}

const strapiServiceInstance = new StrapiService();
export default strapiServiceInstance;
