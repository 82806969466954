class PageTemplateController {
  private pageDataController: any;
  constructor(pageDataController: any) {
    this.pageDataController = pageDataController;
  }

  private mapSections = (
    templateSections: any[],
    pageSections: any[]
  ): any[] => {
    let map = {};
    pageSections.forEach((item) => {
      map[item.__component] = item;
    });
    let sections: any[] = [];
    sections = templateSections
      .map((item) => {
        if (map[item.__component]) {
          return map[item.__component];
        }
        if (item.section_id) {
          return item;
        }
        return false;
      })
      .filter((item) => item);
    return sections;
  };

  public mapTemplate = async (
    {
      pageQuery,
      locale,
      defaultLocale,
      onlyCustomData,
    }: {
      pageQuery: string;
      locale: string;
      defaultLocale: string;
      onlyCustomData: boolean;
    },
    pageData: any
  ): Promise<{ pageDataTemplate: any; customPageDataTemplate: any }> => {
    try {
      let newPage: any = JSON.parse(JSON.stringify(pageData));
      let templateData: any = { ...newPage.template };
      delete newPage["template"];
      let templatePageData = await this.pageDataController.getPageData(
        templateData.template.url.replace("/resources/", ""),
        locale,
        defaultLocale
      );

      templatePageData.pageData.sections = this.mapSections(
        templatePageData?.pageData?.sections,
        pageData.sections
      );

      if (!templateData.mapSeo) {
        templatePageData.pageData.seo = pageData?.seo;
      }

      return {
        pageDataTemplate: templatePageData?.pageData,
        customPageDataTemplate: templateData?.custompageData,
      };
    } catch (err) {
      console.log("[PageTemplate.controller]", err);
      return { pageDataTemplate: undefined, customPageDataTemplate: undefined };
      //http://localhost:3000/resources/test/test
    }
  };
}

export default PageTemplateController;
