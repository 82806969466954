class BaseSectionFormatController {
  constructor() {
    this.formatSectionMap = {
      "crs-detail": this.#formatCourseDetailSection,
    };
  }

  #formatCourseDetailSection = (sectionData) => {
    const {
      stickyHeader,
      courseMedia,
      courseMediaId,
      stickyListId,
      stickyListHeading,
      stickyListData,
      textualSectionId,
      dualListId,
      dualListData1,
      dualListData2,
      dualListIcon,
      dualListHeading,
      checkListIcon,
      checkListData,
      checkListId,
      checkListHeading,
      paragraphId,
      paragraphVisible,
      paragraphHidden,
      paragraphHeading,
      dropDownId,
      dropDownHeading,
      dropDownCTA,
      dropDownListData,
      imgDescriptionId,
      imgDescriptionHeading,
      imgDescriptionName,
      imgDescriptionBriefText,
      imgDescriptionParagraph,
      imgDescriptionReviews,
      imgDescriptionRating,
      imgDescriptionImage,
      imgDescriptionIcons,
      imgDescriptionMeta,
      sectionsOrder,
      cardSections = [],
      scrollCardSections = [],
      id,
      __component,
      waitlistCardId,
      waitlistCardHeading,
      waitlistCardMobileSubheading,
      waitlistCardDesktopSubheading,
      waitlistCardCTAText,
      waitlistCardCTALink,

      stickyCtaBtnsId,
      stickyCta1,
      stickyCta2,
      stickyCtaBtnsMiddleText,
      stickyCtaBtnsMiddleLink,
      stickyCtaBtnsHeading,
      stickyCtaBtnsSubheading,
    } = sectionData;

    let formattedSectionData = {
      id,
      __component,
      stickyHeader,
      courseMedia,
      courseMediaId,
      stickyListId,
      stickyListHeading,
      stickyListData,
      waitlistCardId,
      waitlistCardHeading,
      waitlistCardMobileSubheading,
      waitlistCardDesktopSubheading,
      waitlistCardCTAText,
      waitlistCardCTALink,
      stickyCtaBtnsId,
      stickyCta1,
      stickyCta2,
      stickyCtaBtnsMiddleText,
      stickyCtaBtnsMiddleLink,
      stickyCtaBtnsHeading,
      stickyCtaBtnsSubheading,
    };

    let propsMapping = {
      [textualSectionId]: {
        section_id: textualSectionId,
        dualListId,
        dualListData1,
        dualListData2,
        dualListIcon,
        dualListHeading,
        checkListIcon,
        checkListData,
        checkListId,
        checkListHeading,
        paragraphId,
        paragraphVisible,
        paragraphHidden,
        paragraphHeading,
        id: "textual",
        __component: "v2.textual",
      },
      [dropDownId]: {
        cta: dropDownCTA,
        heading: dropDownHeading,
        section_id: dropDownId,
        listData: dropDownListData,
        id: "drop-down",
        __component: "v2.drop-down",
      },
      [imgDescriptionId]: {
        brief: imgDescriptionBriefText,
        description: imgDescriptionParagraph,
        heading: imgDescriptionHeading,
        image: imgDescriptionImage,
        listData: imgDescriptionIcons,
        listData2Meta: imgDescriptionMeta,
        name: imgDescriptionName,
        rating: imgDescriptionRating,
        reviews: imgDescriptionReviews,
        section_id: imgDescriptionId,
        id: "dtls-crd",
        __component: "v2.dtls-crd",
      },
    };

    for (let cardSection of cardSections) {
      if (cardSection.section_id) {
        propsMapping[cardSection?.section_id] = {
          id: `dynm-cards-${cardSection.section_id}`,
          __component: "v2.dynm-crds",
          ...cardSection,
        };
      }
    }

    for (let cardSection of scrollCardSections) {
      if (cardSection.section_id) {
        propsMapping[cardSection?.section_id] = {
          id: `scrl-dynm-cards-${cardSection.section_id}`,
          __component: "v2.scrl-dynm-crds",
          ...cardSection,
        };
      }
    }

    const sections = [];

    Object.values(sectionsOrder || {}).forEach((sectionId) => {
      if (propsMapping[sectionId]) {
        sections.push(propsMapping[sectionId]);
      }
    });

    formattedSectionData.sections = sections;

    return formattedSectionData;
  };

  getFormattedSectionData(sections) {
    try {
      let refinedSections = [];

      for (let section of sections) {
        let sectionName =
          section.__component?.split("section.")[1] ||
          section.__component?.split("v2.")[1];

        if (this.formatSectionMap[sectionName]) {
          let sectionData = this.formatSectionMap[sectionName](section);
          refinedSections.push(sectionData);
        } else {
          refinedSections.push(section);
        }
      }

      return refinedSections;
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }
}

export default BaseSectionFormatController;
